import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const EditCategory = ({ props }) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [category, setCategory] = useState();
  const [slug, setSlug] = useState();
  const [categoryImage, setcategoryImage] = useState();
  const [isPopular, setisPopular] = useState();
  const [isAuctionShow, setIsAuctionShow] = useState();
  const [displayOrder, setdisplayOrder] = useState();
  const [buyerProtecation, setBuyerProtecation] = useState([{
    range: {
      from: "",
      to: ""
    },
    buy_now: {
      buyer: "",
      private_seller: "",
      business_seller: "",
      soletrader_seller: ""
    },
    auction: {
      buyer: "",
      private_seller: "",
      business_seller: "",
      soletrader_seller: ""
    }
  }]
  );

  const [categoryFields, setCategoryFields] = useState([{ value: null }]);
  const [categoryTypes, setCategoryTypes] = useState([{ value: null }]);

  const id = props;
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    axios.post(`${PORT}/getCategory`, { params: { id } }).then((res, err) => {
      console.log("editedcategory", res);
      setCategory(res.data.categoryName);
      setSlug(res.data.slug);
      setisPopular(res.data.IsPopular);
      setIsAuctionShow(res.data.isAuctionShow);
      setdisplayOrder(res.data.displayOrder);
      setcategoryImage(res.data.categoryImage);
      setBuyerProtecation(res.data.buyerProtecation);
    });
  }, [props]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!category) {
      document.getElementById("category").style.display = "block";
      return;
    }
    if (!displayOrder) {
      document.getElementById("order").style.display = "block";
      return;
    }
    if (displayOrder == -1) {
      document.getElementById("exist-orderid").style.display = "block";
      return;
    }
    else {
      await axios
        .post(`${PORT}/updateCategory`, {
          params: { id, category, slug, categoryImage, isPopular, isAuctionShow, displayOrder, buyerProtecation },
        })
        .then((res, err) => {
          if (res) {
            handleClose();
            history.push({ pathname: "/categories", MatchValue: "edit" });
          }
        });
    }
  };

  const handleCategory = (event) => {
    event.preventDefault();
    document.getElementById("category").style.display = "none";
    setCategory(event.target.value);

    let data = event.target.value;
    let data1 = data.toLowerCase();

    data1 = data1.replace(/\s/g, "-");

    setSlug(data1);
  };

  const handleSlug = (event) => {
    event.preventDefault();
    setSlug(event.target.value);
  };

  const handleMainCategoryImage = (event) => {
    const files = event.target.files[0];

    let fData = new formData();
    fData.append("categoryImage", files);

    var config = {
      method: "post",
      url: `${PORT}/uploadCategory`,
      data: fData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success == 1) {
          console.log(response.data.image_url.split("/"));
          const image = response.data.image_url.split("/");
          setcategoryImage(image[6]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleisPopular = (e) => {
    if (e.target.checked == true) {
      setisPopular(true);
    } else {
      setisPopular(false);
    }
  };

  const handleAuctionShow = (e) => {
    if (e.target.checked == true) {
      setIsAuctionShow(true);
    } else {
      setIsAuctionShow(false);
    }
  };

  const handledisplayOrder = async (e) => {
    e.preventDefault();
    document.getElementById("order").style.display = "none";
    setdisplayOrder(e.target.value);

    console.log("dfvjcdsnfjvndjsf", e.target.value);

    if (e.target.value.trim()) {
      await axios
        .post(`${PORT}/get_by_order`, { orderId: e.target.value })
        .then((res, err) => {
          if (res.data.displayOrder) {
            document.getElementById("exist-orderid").style.display = "block";
            setdisplayOrder(-1);
            return;
          } else {
            document.getElementById("exist-orderid").style.display = "none";
            setdisplayOrder(e.target.value);
          }
        });
    } else {
      document.getElementById("exist-orderid").style.display = "none";
    }
  };

  const handleClose = (event) => {
    document.body.classList.remove('fixedit_modal');
  }

  const handleFormChange = (index, event, name) => {

    if (name != "range") {
      if (Number(event.target.value) < 0 || Number(event.target.value) > 100) {
        return false;
      }
    }

    let data = [...buyerProtecation];
    data[index][name][event.target.name] = event.target.value;
    setBuyerProtecation(data);

  }

  const addPriceRangeBlock = (i, e) => {
    let newfield = {
      range: {
        from: "",
        to: ""
      },
      buy_now: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      },
      auction: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      }
    };

    setBuyerProtecation([...buyerProtecation, newfield]);
  };


  const removePriceRangeBlock = (i, e) => {
    let data = [...buyerProtecation];
    data.splice(i, 1);
    setBuyerProtecation(data);
  };

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
        <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Category Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Category Name</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleCategory}
                          type="text"
                          class="fuilddesh"
                          placeholder=""
                          value={category}
                        />
                        <p id="category" style={{ display: "none" }} className="text-danger">Category is required*</p>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "none" }} class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Slug</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleSlug}
                          type="text"
                          class="fuilddesh"
                          placeholder=""
                          value={slug}
                        />
                      </div>
                    </div>
                  </div>


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Category Image</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleMainCategoryImage}
                          type="file"
                          class=""
                          name="img"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>



                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Is Popular</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          type="checkbox"
                          checked={isPopular}
                          onClick={handleisPopular}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Allow Auction Show</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                        <input
                    type="checkbox"
                    checked={isAuctionShow}
                    onClick={handleAuctionShow}
                  />
                    </div>
                  </div>
                </div> */}



                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Category Order</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handledisplayOrder}
                          value={displayOrder}
                          type="number"
                          class="fuilddesh"
                          placeholder=""
                          min="1"
                        />
                        <p
                          style={{ display: "none" }}
                          id="exist-orderid"
                          className="text-danger"
                        >
                          Order Id already exist
                        </p>
                        <p
                          style={{ display: "none" }}
                          id="order"
                          className="text-danger"
                        >
                          Order Id is required
                        </p>

                      </div>
                    </div>
                  </div>
                  {/* <div className="bindfields">
                  <h4 className="fieldsec_lab">Buyer protection fee (%)<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                  {buyerProtecation && buyerProtecation.length > 0 && buyerProtecation.map((data,index) => {
                  return (<div key={index}>
                    <div className="row repwat_one">
                      <div className="col-3">
                        <p>Price Range</p>
                      </div>
                      <div className="col-3 mb-2">
                        <input className="fuilddesh" name="from" type="number" min="0" step="any" placeholder="From" onChange={ event => handleFormChange(index,event,'range') } value={data?.range?.from} />
                      </div>
                      <div className="col-3 mb-2">
                        <input className="fuilddesh" name="to" type="number" min="0" step="any" placeholder="To" onChange={ event => handleFormChange(index,event,'range') } value={data?.range?.to} />
                      </div>
                      {index === 0 ? 
                      <div className="col-3 mb-2">
                        <a className="cursor-pointer" href={void(0)} onClick= {event => addPriceRangeBlock(index, event)} >Add more</a>
                      </div> : <div className="col-3 mb-2">
                        <a className="cursor-pointer" href={void(0)} onClick= {event => removePriceRangeBlock(index, event)} >Remove</a>
                      </div> }
                    </div>
                    <div className="row repwat_one">
                      <div className="col-3">
                        <p>Buy now</p>
                      </div>
                      <div className="row col-9 mb-2">
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="buyer" type="number" min="0" max="100" step="any" placeholder="Buyer" onChange={ event => handleFormChange(index,event,'buy_now') } value={data?.buy_now?.buyer} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="private_seller" type="number" min="0" max="100" step="any"  placeholder="Private seller" onChange={ event => handleFormChange(index,event,'buy_now') } value={data?.buy_now?.private_seller} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business seller" onChange={ event => handleFormChange(index,event,'buy_now') } value={data?.buy_now?.business_seller} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader seller" onChange={ event => handleFormChange(index,event,'buy_now') } value={data?.buy_now?.soletrader_seller} />
                        </div>
                      </div>
                    </div>
                    <div className="row repwat_one">
                      <div className="col-3">
                        <p>Auction</p>
                      </div>
                      <div className="row col-9 mb-2">
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="buyer" type="number" min="0" max="100" step="any" placeholder="Buyer" onChange={ event => handleFormChange(index,event,'auction') }  value={data?.auction?.buyer} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="private_seller" type="number" min="0" step="any" max="100" placeholder="Private seller" onChange={ event => handleFormChange(index,event,'auction') }  value={data?.auction?.private_seller} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business seller" onChange={ event => handleFormChange(index,event,'auction') }  value={data?.auction?.business_seller} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader seller" onChange={ event => handleFormChange(index,event,'auction') }  value={data?.auction?.soletrader_seller} />
                        </div>
                      </div>
                    </div>
                    <hr/>
                  </div>);
                  })}
                  </div> */}

                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">SAVE</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditCategory;



{/* <div class="work_space">
<div class="workspace_title_hd">
  <div class="row">
    <div class="col-md-12">
      <div class="titleof_page">
        <ul class="breadcrumb_list topsmall_bottom">
          <li>
            <a href="#">Admin Panel </a>
          </li>
          <li>
            <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
          </li>
          <li>Edit Category</li>
        </ul>
        <h2 class="main_title">Edit Category</h2>
      </div>
    </div>
    <div class="col-md-12">
      <div class="main_oflistingForm bg_light_gray onlyforcol_support">
        <div class="custom_fuild_addM topsmall_top flexcoluman edit-form">
          <form onSubmit={handleSubmit}>
            <ToastContainer />
     <div class="row">
              <div class="col-md-6">
            <div class="form-group">
              <h4 class="fieldsec_lab">Category Name<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
              <div class="">
                <div class="repwat_one">
                  <input
                    onChange={handleCategory}
                    type="text"
                    class="fuilddesh"
                    placeholder="Category Name"
                    value={category}
                    required
                  />
                
                </div>
              </div>
            </div>
            </div>
            
            <input type="hidden" onChange={handleSlug} class="fuilddesh" name="" placeholder="Slug" value={slug} required/>
            <div class="col-md-6">
           
            <div class="form-group">
              <h4 class="fieldsec_lab">Category Image</h4>
              <div class="">
                <div class="repwat_one">
                  <input
                    onChange={handleMainCategoryImage}
                    type="file"
                    class="fuilddesh"
                    name="img"
                    placeholder="Category Image"
                  />
                </div>
              </div>
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group">
              <h4 class="fieldsec_lab">Is Popular</h4>
              <div class="">
                <div class="repwat_one">
                  <input
                    type="checkbox"
                    checked={isPopular}
                    onClick={handleisPopular}
                  />
                </div>
              </div>
            </div>
            </div>
            <div class="col-md-6">
            <div class="form-group">
              <h4 class="fieldsec_lab">Category Order<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
              <div class="">
                <div class="repwat_one">
                  <input
                    onChange={handledisplayOrder}
                    value={displayOrder}
                    type="number"
                    class="fuilddesh"
                    placeholder="Category Order"
                    min="1"
                    required
                  />
                  <p
                    style={{ display: "none" }}
                    id="exist-orderid"
                    className="text-danger"
                  >
                    Order Id already exist
                  </p>
                </div>
        
              </div>
            </div>
            </div>
            </div>
            <div className="bottomsend_button text-center small_margin_top">
              <button type="submit" className="savesidebutt closefix">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</div> */}
