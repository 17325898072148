import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import formData from "form-data";

import PORTNUMBER from "../../../constant/api";

const EditSubCategory = ({ props }) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allowBuyNow, setAllowBuyNow] = useState();
  const [isAuctionShow, setIsAuctionShow] = useState();

  const [subCategoryName, setSubCategory] = useState();
  const [slug, setSlug] = useState();
  const [image_name, setImageName] = useState();

  const [selectCategoryField, setSelectCategoryField] = useState();
  const [selectCategoryType, setSelectCategoryType] = useState();

  const [allCategoryField, setAllCategoryField] = useState();
  const [allCategoryType, setAllCategoryType] = useState();

  const [allCategory, setAllCategory] = useState([{ value: null }]);
  const [allSubCategory, setAllSubCategory] = useState([{ value: null }]);

  const [buyerProtecation, setBuyerProtecation] = useState([{
    range: {
      from: "",
      to: ""
    },
    buy_now: {
      buyer: "",
      private_seller: "",
      business_seller: "",
      soletrader_seller: ""
    },
    auction: {
      buyer: "",
      private_seller: "",
      business_seller: "",
      soletrader_seller: ""
    }
  }]
  );

  //console.log(allSubCategory,'allSubCategory')
  const [categoryId, setSelectCategory] = useState();
  const [parentId, setSelectParentCategory] = useState();

  const id = props;
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;

  useEffect(async () => {
    await axios.get(`${PORT}/getCategorys`).then((response, err) => {
      setAllCategory(response);
    });

    await axios
      .post(`${PORT}/getOtherSubCategories/`, { params: { id } })
      .then((res, err) => {
        console.log("MahiSubcategories => ", res);
        setAllSubCategory(res);
      });
  }, []);

  useEffect(async () => {
    await axios
      .post(`${PORT}/getFirstSubCategoryEdit`, { params: { id } })
      .then((res, err) => {
        console.log("editedit", res);
        setSubCategory(res.data.subCategoryName);
        setSlug(res.data.slug);
        setSelectCategory(res.data.categoryId);
        setSelectParentCategory(res.data.parentId);
        setImageName(res.data.subCategoryImage);
        setAllowBuyNow(res.data.allowBuyNow)
        setIsAuctionShow(res.data.isAuctionShow);
        setBuyerProtecation(res.data.buyerProtecation);
      });
  }, [props]);

  const handleCategoryName = async (event) => {
    document.getElementById("category-name").style.display = "none";
    setSelectCategory(event.target.value);
  };

  const handleParentCategory = async (event) => {
    console.log(event.target.value);
    setSelectParentCategory(event.target.value);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setSubCategory("");
    setSlug("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!categoryId) {
      document.getElementById("category-name").style.display = "block";
      return;
    }

    if (!subCategoryName) {
      document.getElementById("sub-category-name").style.display = "block";
      return;
    }

    await axios
      .post(`${PORT}/editFirstSubCategory`, {
        params: {
          id,
          subCategoryName,
          subCategoryImage: image_name,
          slug,
          categoryId,
          parentId,
          allowBuyNow,
          isAuctionShow,
          buyerProtecation
        },
      })
      .then((res, err) => {
        if (res) {
          handleClose();
          history.push({ pathname: "/sub-categories", MatchValue: "edit" });
        }
      });
  };

  const handleSubCategory = (event) => {
    event.preventDefault();
    document.getElementById("sub-category-name").style.display = "none";
    setSubCategory(event.target.value);

    let data = event.target.value;
    let data1 = data.toLowerCase();

    data1 = data1.replace(/\s/g, "-");

    setSlug(data1);
  };

  const handleSlug = (event) => {
    event.preventDefault();
    document.getElementById("slug").style.display = "none";
    setSlug(event.target.value);
  };

  const handleMultipleCategoryFieldSelect = (data) => {
    data.map((selectdata) => {
      if (selectdata.value === "Category Type") {
        return (document.getElementById("show-type").style.display = "block");
      }
    });
    setSelectCategoryField(data);
  };

  const handleMultipleCategoryFieldRemove = (data) => {
    data.map((selectdata) => {
      if (selectdata.value === "Category Type") {
        return;
      } else {
        return (document.getElementById("show-type").style.display = "none");
      }
    });
  };

  const handleMultipleCategoryType = (data) => {
    setSelectCategoryType(data);
  };

  const handleImage = async (event) => {
    const files = event.target.files[0];
    // const image = await resizeFile(files);



    let fData = new formData();
    await fData.append("subcategoryImage", files);

    var config = {
      method: "post",
      url: `${PORT}/subcategory/image`,
      data: fData,
    };

    await axios(config)
      .then(function (response) {
        if (response.data.success == 1) {
          console.log(response.data.image_url.split("/"));
          const faqcategoryimage = response.data.image_url.split("/");
          setImageName(faqcategoryimage[6]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClose = (event) => {
    document.body.classList.remove("fixedit_modal");
  };



  const handleAllowBuyNow = (e) => {
    setAllowBuyNow(e.target.checked)
  }

  const handleAuctionShow = (e) => {
    setIsAuctionShow(e.target.checked)
  }


  const handleFormChange = (index, event, name) => {

    if (name != "range") {
      if (Number(event.target.value) < 0 || Number(event.target.value) > 100) {
        return false;
      }
    }

    let data = [...buyerProtecation];

    data[index][name][event.target.name] = event.target.value;
    setBuyerProtecation(data);

  }

  const addPriceRangeBlock = (i, e) => {
    let newfield = {
      range: {
        from: "",
        to: ""
      },
      buy_now: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      },
      auction: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      }
    };

    setBuyerProtecation([...buyerProtecation, newfield]);
  };


  const removePriceRangeBlock = (i, e) => {
    let data = [...buyerProtecation];
    data.splice(i, 1);
    setBuyerProtecation(data);
  };

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn">
          <i class="fa fa-check"></i>
        </a>
        <a onClick={handleClose} class="fixhbtn closefix">
          <i class="fa fa-times"></i>
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Sub category Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Category</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleCategoryName}
                          value={categoryId}
                          class="fuilddesh "
                        >
                          <option value={""}>{"SELECT CATEGORY"}</option>
                          {allCategory &&
                            allCategory.data &&
                            allCategory.data.map((cat) => {
                              return (
                                <option value={cat._id}>
                                  {cat.categoryName}
                                </option>
                              );
                            })}
                        </select>
                        <p
                          className="text-danger"
                          id="category-name"
                          style={{ display: "none" }}
                        >
                          Category Name is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Parent Category</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleParentCategory}
                          value={parentId}
                          class="fuilddesh "
                        >
                          <option value={""}>{"Select Parent Category"}</option>
                          {allSubCategory &&
                            allSubCategory.data &&
                            allSubCategory.data.map((cat) => {
                              return (
                                <option value={cat._id}>
                                  {cat.subCategoryName + ' (' + cat.label + ')'}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Sub category Name</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleSubCategory}
                          type="text"
                          class="fuilddesh "
                          placeholder=""
                          value={subCategoryName}
                        />
                        <p
                          className="text-danger"
                          id="sub-category-name"
                          style={{ display: "none" }}
                        >
                          Sub Category Name is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Sub category Image</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleImage}
                          type="file"
                          class=""
                          name="img"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  {
                    !parentId && (
                      <>
                        <div class="Table-row">
                          <div class="col-md-6">
                            <div class="form-check p-4" >
                              <input class="form-check-input" type="checkbox" checked={allowBuyNow} id="flexCheckChecked" onChange={e => handleAllowBuyNow(e)} />
                              <label class="form-check-label " for="flexCheckChecked">
                                Allow buy now
                              </label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check p-4" >
                              <input class="form-check-input" type="checkbox" checked={isAuctionShow} id="flexAuctionShowChecked" onChange={e => handleAuctionShow(e)} />
                              <label class="form-check-label " for="flexAuctionShowChecked">
                                Allow Auction Show
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h4 className="fieldsec_lab">Buyer protection fee (%)<p className="text-danger" style={{ display: "inline-block" }}>*</p></h4>
                          {buyerProtecation && buyerProtecation.length > 0 && buyerProtecation.map((data, index) => {
                            return (<div key={index}>
                              <div className="row repwat_one">
                                <div className="col-3">
                                  <p>Price Range</p>
                                </div>
                                <div className="col-3 mb-2">
                                  <input className="fuilddesh" name="from" type="number" min="0" step="any" placeholder="From" onChange={event => handleFormChange(index, event, 'range')} value={data?.range?.from} />
                                </div>
                                <div className="col-3 mb-2">
                                  <input className="fuilddesh" name="to" type="number" min="0" step="any" placeholder="To" onChange={event => handleFormChange(index, event, 'range')} value={data?.range?.to} />
                                </div>
                                {index === 0 ?
                                  <div className="col-3 mb-2">
                                    <a className="cursor-pointer" href={void (0)} onClick={event => addPriceRangeBlock(index, event)} >Add more</a>
                                  </div> : <div className="col-3 mb-2">
                                    <a className="cursor-pointer" href={void (0)} onClick={event => removePriceRangeBlock(index, event)} >Remove</a>
                                  </div>}
                              </div>
                              <div className="row repwat_one">
                                <div className="col-3">
                                  <p>Buy now</p>
                                </div>
                                <div className="row col-9 mb-2">
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="buyer" type="number" min="0" max="100" step="any" placeholder="Buyer" onChange={event => handleFormChange(index, event, 'buy_now')} value={data?.buy_now?.buyer} />
                                  </div>
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="private_seller" type="number" min="0" max="100" step="any" placeholder="Private seller" onChange={event => handleFormChange(index, event, 'buy_now')} value={data?.buy_now?.private_seller} />
                                  </div>
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business seller" onChange={event => handleFormChange(index, event, 'buy_now')} value={data?.buy_now?.business_seller} />
                                  </div>
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader seller" onChange={event => handleFormChange(index, event, 'buy_now')} value={data?.buy_now?.soletrader_seller} />
                                  </div>
                                </div>
                              </div>
                              <div className="row repwat_one">
                                <div className="col-3">
                                  <p>Auction</p>
                                </div>
                                <div className="row col-9 mb-2">
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="buyer" type="number" min="0" max="100" step="any" placeholder="Buyer" onChange={event => handleFormChange(index, event, 'auction')} value={data?.auction?.buyer} />
                                  </div>
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="private_seller" type="number" min="0" step="any" max="100" placeholder="Private seller" onChange={event => handleFormChange(index, event, 'auction')} value={data?.auction?.private_seller} />
                                  </div>
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business seller" onChange={event => handleFormChange(index, event, 'auction')} value={data?.auction?.business_seller} />
                                  </div>
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader seller" onChange={event => handleFormChange(index, event, 'auction')} value={data?.auction?.soletrader_seller} />
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>);
                          })}
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">
              SAVE
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditSubCategory;

{
  /* <div class="work_space">
<div class="workspace_title_hd">
  <div class="row">
    <div class="col-md-12">
      <div class="titleof_page">
        <ul class="breadcrumb_list topsmall_bottom">
          <li>
            <a href="#">Admin Panel </a>
          </li>
          <li>
            <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
          </li>
          <li>Edit sub Category</li>
        </ul>
        <h2 class="main_title">Edit sub Category</h2>
      </div>
    </div>
    <div class="col-md-12">
      <div class="main_oflistingForm bg_light_gray onlyforcol_support">
        <div class="custom_fuild_addM topsmall_top flexcoluman  edit-form">
          <form onSubmit={handleSubmit}>
            <ToastContainer />


            
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <h4 class="fieldsec_lab">
                    Category
                    <p
                      className="text-danger"
                      style={{ display: "inline-block" }}
                    >
                      *
                    </p>
                  </h4>
                  <div class="">
                    <div class="repwat_one">
                      <select
                        onChange={handleCategoryName}
                        value={categoryId}
                        class="fuilddesh "
                        
                      >
                        <option value={""}>{"SELECT CATEGORY"}</option>
                        {allCategory &&
                          allCategory.data &&
                          allCategory.data.map((cat) => {
                            return (
                              <option value={cat._id}>
                                {cat.categoryName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <p
                    className="text-danger"
                    id="category-name"
                    style={{ display: "none" }}
                  >
                    Category Name is required*
                  </p>
                </div>
              </div>



              <div class="col-md-6">
                <div class="form-group">
                  <h4 class="fieldsec_lab">
                    Parent category<a href="javascript:void(0)"></a>
                  </h4>
                  <div class="">
                    <div class="repwat_one">
                      <select
                        onChange={handleParentCategory}
                        value={parentId}
                        class="fuilddesh "
                      >
                        <option value={""}>
                          {"Select Parent Category"}
                        </option>
                        {allSubCategory &&
                          allSubCategory.data &&
                          allSubCategory.data.map((cat) => {
                            return (
                              <option value={cat._id}>
                                {cat.subCategoryName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
             
                </div>
              </div>



              <div class="col-md-6">
                <div class="form-group mt-1">
                  <h4 class="fieldsec_lab">
                    Sub Category Name
                    <p
                      className="text-danger"
                      style={{ display: "inline-block" }}
                    >
                      *
                    </p>
                  </h4>
                  <div class="">
                    <div class="repwat_one">
                      <input
                        onChange={handleSubCategory}
                        type="text"
                        class="fuilddesh1  "
                        placeholder="Sub Category Name"
                        value={subCategoryName}
                      />
                    </div>
                  </div>
                  <p
                    className="text-danger"
                    id="sub-category-name"
                    style={{ display: "none" }}
                  >
                   Sub Category Name is required*
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <h4 class="fieldsec_lab">
                    Sub Category Image
                  
                  </h4>
                  <div class="">
                    <div class="repwat_one">
                      <input
                        onChange={handleImage}
                        type="file"
                        class="fuilddesh1  "
                        name="img"
                        placeholder="Image"
                      />
                    
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div style={{ display: "none" }} class="form-group">
                  <h4 class="fieldsec_lab">
                    Slug
                    <p
                      className="text-danger"
                      style={{ display: "inline-block" }}
                    >
                      *
                    </p>
                  </h4>
                  <div class="">
                    <div class="repwat_one">
                      <input
                        onChange={handleSlug}
                        type="text"
                        class="fuilddesh1  "
                        name=""
                        placeholder="Slug"
                        value={slug}
                      />
                    </div>
                  </div>
                  <p
                    className="text-danger"
                    id="slug"
                    style={{ display: "none" }}
                  >
                    Slug is required*
                  </p>
                </div>
              </div>
            </div>

            <div className="bottomsend_button text-center small_margin_top">
              <button type="submit" className="savesidebutt closefix">
                Save
              </button>
              <button
                type="button"
                onClick={handleReset}
                className="savesidebutt closefix"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</div> */
}
