import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./addCategory.css";
import PORTNUMBER from "../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const AddCategory = () => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [category, setCategory] = useState();
  const [slug, setSlug] = useState();
  const [categoryImage, setcategoryImage] = useState();
  const [isPopular, setIsPopular] = useState();
  const [isAuctionShow, setIsAuctionShow] = useState(false);
  const [displayOrder, setOrder] = useState();
  const [buyerProtecation, setBuyerProtecation] = useState([{
    range: {
      from: "",
      to: ""
    },
    buy_now: {
      buyer: "",
      private_seller: "",
      business_seller: "",
      soletrader_seller: ""
    },
    auction: {
      buyer: "",
      private_seller: "",
      business_seller: "",
      soletrader_seller: ""
    }
  }]
  );

  /* buy_now_buyer : 0,
    buy_now_private_seller : 0,
    buy_now_business_seller : 0,
    auction_buyer : 0,
    auction_private_seller : 0,
    auction_business_seller : 0, */

  const [dropName, setDropName] = useState([{ value: null }]);
  const [alldropName, setAllDropName] = useState([{ value: null }]);
  const [categoryFields, setCategoryFields] = useState([{ value: null }]);
  const [categoryTypes, setCategoryTypes] = useState([{ value: null }]);

  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const handleReset = (event) => {
    event.preventDefault();
    setCategory("");
    setSlug("");
    setCategoryFields([null]);
    setCategoryTypes([null]);
    setBuyerProtecation([{
      range: {
        from: "",
        to: ""
      },
      buy_now: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      },
      auction: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      }
    }]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (displayOrder == -1) {
      document.getElementById("exist-orderid").style.display = "block";
      return;
    } else {
      var id = Math.floor(Math.random() * 10000);

      await axios
        .post(`${PORT}/addCategory`, {
          params: { id, category, slug, categoryImage, isPopular, isAuctionShow, displayOrder, buyerProtecation },
        })
        .then((res, err) => {
          toast.success("You have successfully added a new category");
        });
      setCategory("");
      setSlug("");
      history.push({ pathname: "/categories", customdata: { pathname } });
    }
  };

  const handleCategory = (event) => {
    event.preventDefault();
    setCategory(event.target.value);

    let data = event.target.value;
    let data1 = data.toLowerCase();

    data1 = data1.replace(/\s/g, "-");

    setSlug(data1);
  };

  const handleSlug = (event) => {
    event.preventDefault();
    setSlug(event.target.value);
  };

  const handleMainCategoryImage = (event) => {
    const files = event.target.files[0];

    let fData = new formData();
    fData.append("categoryImage", files);

    var config = {
      method: "post",
      url: `${PORT}/uploadCategory`,
      data: fData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success == 1) {
          const image = response.data.image_url.split("/");
          setcategoryImage(image[6]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleIsPopular = (e) => {
    if (e.target.checked == true) {
      setIsPopular(true);
    } else {
      setIsPopular(false);
    }
  };

  const handleAuctionShow = (e) => {
    if (e.target.checked == true) {
      setIsAuctionShow(true);
    } else {
      setIsAuctionShow(false);
    }
  };

  const handleCategoryOrder = async (e) => {
    if (e.target.value.trim()) {
      await axios
        .post(`${PORT}/get_by_order`, { orderId: e.target.value })
        .then((res, err) => {
          if (res.data.categoryOrder) {
            document.getElementById("exist-orderid").style.display = "block";
            setOrder(-1);
            return;
          } else {
            document.getElementById("exist-orderid").style.display = "none";
            setOrder(e.target.value);
          }
        });
    } else {
      document.getElementById("exist-orderid").style.display = "none";
    }
  };

  const handleDropdown = async (index, e) => {
    if (e.target.value == "dropdown") {
      console.log(index, categoryFields[index]);
      await setDropName((dropName) => [...dropName, categoryFields[index]]);
    } else {
      return;
    }
  };

  const handleChangeTypes = async (i, event, fieldname) => {
    console.log("fieldname", fieldname);

    await setAllDropName((alldropName) => [
      ...alldropName,
      fieldname,
      [event.target.value],
    ]);

    const values = [...categoryTypes];
    values[i].value = event.target.value;
    setCategoryTypes(values);
  };

  const handleAddTypes = () => {
    const values = [...categoryTypes];
    values.push({ value: null });
    setCategoryTypes(values);
  };

  const handleRemoveTypes = (i) => {
    const values = [...categoryTypes];
    values.splice(i, 1);
    setCategoryTypes(values);
  };
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleChangeFields = (i, event) => {
    const values = [...categoryFields];
    values[i].value = event.target.value;
    setCategoryFields(values);
    console.log(categoryFields);
  };

  const handleAddFields = () => {
    const values = [...categoryFields];
    values.push({ value: null });
    setCategoryFields(values);
  };

  const handleRemoveFields = (i) => {
    const values = [...categoryFields];
    values.splice(i, 1);
    setCategoryFields(values);
  };

  const handleFormChange = (index, event, name) => {

    if (name != "range") {
      if (Number(event.target.value) < 0 || Number(event.target.value) > 100) {
        return false;
      }
    }

    let data = [...buyerProtecation];

    data[index][name][event.target.name] = (event.target.value != undefined) ? event.target.value : "";
    setBuyerProtecation(data);

  }

  const addPriceRangeBlock = (i, e) => {
    let newfield = {
      range: {
        from: "",
        to: ""
      },
      buy_now: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      },
      auction: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      }
    };

    setBuyerProtecation([...buyerProtecation, newfield]);
  };


  const removePriceRangeBlock = (i, e) => {
    let data = [...buyerProtecation];
    data.splice(i, 1);
    setBuyerProtecation(data);
  };

  return (
    <div className="work_space">
      <div className="workspace_title_hd">
        <div className="row">
          <div className="col-md-12">
            <div className="titleof_page">
              <ul className="breadcrumb_list topsmall_bottom">
                <li>
                  <a href={void (0)}>Admin Panel </a>
                </li>
                <li>
                  <i
                    style={{ padding: "5px" }}
                    className="fa fa-angle-right"
                  ></i>
                </li>
                <li>Add Category</li>
              </ul>
              <h2 className="main_title">Add Category</h2>
            </div>
          </div>
          <div className="col-md-12">
            <div className="main_oflistingForm bg_light_gray onlyforcol_support">
              <div className="custom_fuild_addM topsmall_top flexcoluman">
                <form onSubmit={handleSubmit}>
                  <ToastContainer />

                  <div className="bindfields">
                    <h4 className="fieldsec_lab">Category Name<p className="text-danger" style={{ display: "inline-block" }}>*</p></h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input
                          onChange={handleCategory}
                          type="text"
                          className="fuilddesh"
                          placeholder="Category Name"
                          value={category}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bindfields">
                    <h4 className="fieldsec_lab">Slug</h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input
                          onChange={handleSlug}
                          type="text"
                          className="fuilddesh"
                          name=""
                          placeholder="Slug"
                          value={slug}
                          required
                        />
                        <span className="requlab_span">
                          <div className="checkBtablss">
                            <ul>
                              <li>
                                <div className="check-box">
                                  <label htmlFor="Company103">
                                    *Required Field
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bindfields">
                    <h4 className="fieldsec_lab">Category Image</h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input
                          onChange={handleMainCategoryImage}
                          type="file"
                          className="fuilddesh"
                          name="img"
                          placeholder="Category Image"
                        />
                        {/* <span className="requlab_span">
                          <div className="checkBtablss">
                            <ul>
                              <li>
                                <div className="check-box">
                                  <label htmlFor="Company103">
                                    *Required Field
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="bindfields">
                    <h4 className="fieldsec_lab">Is Popular</h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input type="checkbox" onClick={handleIsPopular} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="bindfields">
                    <h4 className="fieldsec_lab">Allow Auction Show</h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input type="checkbox" onClick={handleAuctionShow} />
                      </div>
                    </div>
                  </div> */}
                  <div className="bindfields">
                    <h4 className="fieldsec_lab">Display order<p className="text-danger" style={{ display: "inline-block" }}>*</p></h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input
                          onChange={handleCategoryOrder}
                          type="number"
                          className="fuilddesh"
                          placeholder="Display order"
                          min="1"
                          required
                        />
                        <p
                          style={{ display: "none" }}
                          id="exist-orderid"
                          className="text-danger"
                        >
                          Order Id already exist
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="bindfields">
                  <h4 className="fieldsec_lab">Buyer protection fee (%)<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                  {buyerProtecation && buyerProtecation.length > 0 && buyerProtecation.map((data,index) => {
                  return (<div key={index}>
                    <div className="row repwat_one">
                      <div className="col-3">
                        <p>Price Range</p>
                      </div>
                      <div className="col-3 mb-2">
                        <input className="fuilddesh" name="from" type="number" min="0" step="any" placeholder="From" onChange={ event => handleFormChange(index,event,'range') } value={data?.range?.from} />
                      </div>
                      <div className="col-3 mb-2">
                        <input className="fuilddesh" name="to" type="number" min="0" step="any" placeholder="To" onChange={ event => handleFormChange(index,event,'range') } value={data?.range?.to} />
                      </div>
                      {index === 0 ? 
                      <div className="col-3 mb-2">
                        <a className="cursor-pointer" href={void(0)} onClick= {event => addPriceRangeBlock(index, event)} >Add more</a>
                      </div> : <div className="col-3 mb-2">
                        <a className="cursor-pointer" href={void(0)} onClick= {event => removePriceRangeBlock(index, event)} >Remove</a>
                      </div> }
                    </div>
                    <div className="row repwat_one">
                      <div className="col-3">
                        <p>Buy now</p>
                      </div>
                      <div className="row col-9 mb-2">
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="buyer" type="number" min="0" max="100" step="any" placeholder="Buyer" onChange={ event => handleFormChange(index,event,'buy_now') } value={data?.buy_now?.buyer} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="private_seller" type="number" min="0" max="100" step="any"  placeholder="Private seller" onChange={ event => handleFormChange(index,event,'buy_now') } value={data?.buy_now?.private_seller} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business seller" onChange={ event => handleFormChange(index,event,'buy_now') } value={data?.buy_now?.business_seller} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader seller" onChange={ event => handleFormChange(index,event,'buy_now') } value={data?.buy_now?.soletrader_seller} />
                        </div>
                      </div>
                    </div>
                    <div className="row repwat_one">
                      <div className="col-3">
                        <p>Auction</p>
                      </div>
                      <div className="row col-9 mb-2">
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="buyer" type="number" min="0" max="100" step="any" placeholder="Buyer" onChange={ event => handleFormChange(index,event,'auction') }  value={data?.auction?.buyer} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="private_seller" type="number" min="0" step="any" max="100" placeholder="Private seller" onChange={ event => handleFormChange(index,event,'auction') }  value={data?.auction?.private_seller} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business seller" onChange={ event => handleFormChange(index,event,'auction') }  value={data?.auction?.business_seller} />
                        </div>
                        <div className="col-3 mb-2">
                          <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader seller" onChange={ event => handleFormChange(index,event,'auction') }  value={data?.auction?.soletrader_seller} />
                        </div>
                      </div>
                    </div>
                    <hr/>
                  </div>);
                  })}
                  </div> */}
                  <div className="bottomsend_button text-center small_margin_top">
                    <button type="submit" className="savesidebutt closefix">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
