import React, { useState, useEffect, forwardRef } from "react";
import "./AddSubCategory.css";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import formData from "form-data";
import { Multiselect } from "multiselect-react-dropdown";

const AddSubCategory = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [allowBuyNow, setAllowBuyNow] = useState(false);
  const [isAuctionShow, setIsAuctionShow] = useState(false);


  const [subCategoryName, setSubCategory] = useState();
  const [slug, setSlug] = useState();
  const [image_name, setImageName] = useState();

  const [selectCategoryField, setSelectCategoryField] = useState("null");
  const [selectCategoryType, setSelectCategoryType] = useState("null");

  const [allCategory, setAllCategory] = useState([{ value: null }]);
  const [allSubCategory, setAllSubCategory] = useState(null);

  const [buyerProtecation, setBuyerProtecation] = useState([{
    range: {
      from: "",
      to: ""
    },
    buy_now: {
      buyer: "",
      private_seller: "",
      business_seller: "",
      soletrader_seller: ""
    },
    auction: {
      buyer: "",
      private_seller: "",
      business_seller: "",
      soletrader_seller: ""
    }
  }]
  );

  const [allCategoryField, setAllCategoryField] = useState();
  const [allCategoryType, setAllCategoryType] = useState();

  const [categoryId, setSelectCategory] = useState();

  const [parentId, setSelectParentCategory] = useState();
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;
  const category = props.match.params.category;

  const handleCategoryName = async (event) => {
    document.getElementById("category-name").style.display = "none";
    setSelectCategory(event.target.value);
    getSubCatagories(event.target.value)
  };

  const handleParentCategory = async (event) => {
    setSelectParentCategory(event.target.value);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setSubCategory("");
    setSlug("");
    setBuyerProtecation([{
      range: {
        from: "",
        to: ""
      },
      buy_now: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      },
      auction: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      }
    }]);
  };

  const getSubCatagories = async (catgId) => {
    console.log(catgId, "catgId")
    await axios.get(`${PORT}/getSubCategorys`).then((res, err) => {
      console.log(res, "ressub")
      setAllSubCategory(() => {
        if (res.data) {
          let onlyActiveSet = null
          let onlyActive = res.data.filter((val) => {
            return val.status === 'Active' && val?.categoryId?._id === catgId && val.label === '2nd'
          })
          console.log(onlyActive)
          if (onlyActive.length !== 0) {
            onlyActiveSet = [onlyActive[0]]
            console.log(onlyActiveSet)
            onlyActive.forEach((val) => {
              let exists = false
              for (let i = 0; i < onlyActiveSet.length; i++) {
                if (onlyActiveSet[i].subCategoryName === val.subCategoryName) {
                  exists = true
                }
              }
              if (!exists) {
                onlyActiveSet.push(val)
              }
            })
          }
          console.log(onlyActiveSet)
          return onlyActiveSet
        }
      });
      console.log(res)
    });
  }

  useEffect(async () => {
    await axios.get(`${PORT}/getCategorys`).then((response, err) => {
      setAllCategory(response);
      // console.log(response)
    });

    // await axios.get(`${PORT}/getSubCategorys`).then((res, err) => {
    //   console.log(res)
    //   setAllSubCategory(() => {
    //     if (res.data) {
    //       let onlyActive = res.data.filter((val) => {
    //         return val.status === 'Active'
    //       })
    //       console.log(onlyActive)
    //       let onlyActiveSet = [onlyActive[0]]
    //       console.log(onlyActiveSet)
    //       onlyActive.forEach((val) => {
    //         let exists = false
    //         for (let i = 0; i < onlyActiveSet.length; i++) {              
    //           if (onlyActiveSet[i].subCategoryName === val.subCategoryName) {
    //             exists = true
    //           }              
    //         }
    //         if(!exists){
    //           onlyActiveSet.push(val)
    //         }
    //       })
    //       console.log(onlyActiveSet)
    //       return onlyActiveSet
    //     }
    //   });
    //   console.log(res)
    // });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!categoryId) {
      document.getElementById("category-name").style.display = "block";
      return;
    }
    if (!subCategoryName) {
      document.getElementById("sub-category-name").style.display = "block";
      return;
    }


    await axios
      .post(`${PORT}/subCategoryAdd`, {
        params: {
          allowBuyNow,
          isAuctionShow,
          category,
          subCategoryImage: image_name,
          subCategoryName,
          slug,
          categoryId,
          parentId,
          buyerProtecation
        },
      })
      .then((res, err) => {
        console.log(res);
        toast.success("You have successfully added category");
      });
    setSubCategory("");
    setSlug("");
    history.push({ pathname: "/sub-categories", customdata: { pathname } });
  };

  const handleSubCategory = (event) => {
    event.preventDefault();
    document.getElementById("sub-category-name").style.display = "none";
    setSubCategory(event.target.value);

    let data = event.target.value;
    let data1 = data.toLowerCase();

    data1 = data1.replace(/\s/g, "-");

    setSlug(data1);
  };

  const handleSlug = (event) => {
    event.preventDefault();
    document.getElementById("slug").style.display = "none";
    setSlug(event.target.value);
  };

  const handleMultipleCategoryFieldSelect = (data) => {
    data.map((selectdata) => {
      if (selectdata.value === "Category Type") {
        return (document.getElementById("show-type").style.display = "block");
      }
    });
    setSelectCategoryField(data);
  };

  const handleMultipleCategoryFieldRemove = (data) => {
    data.map((selectdata) => {
      if (selectdata.value === "Category Type") {
        return;
      } else {
        return (document.getElementById("show-type").style.display = "none");
      }
    });
  };

  const handleMultipleCategoryType = (data) => {
    setSelectCategoryType(data);
  };

  const handleImage = async (event) => {
    const files = event.target.files[0];
    // const image = await resizeFile(files);

    let fData = new formData();
    await fData.append("subcategoryImage", files);

    var config = {
      method: "post",
      url: `${PORT}/subcategory/image`,
      data: fData,
    };

    await axios(config)
      .then(function (response) {
        if (response.data.success == 1) {
          console.log(response.data.image_url.split("/"));
          const faqcategoryimage = response.data.image_url.split("/");
          setImageName(faqcategoryimage[6]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFormChange = (index, event, name) => {

    if (name != "range") {
      if (Number(event.target.value) < 0 || Number(event.target.value) > 100) {
        return false;
      }
    }

    let data = [...buyerProtecation];

    data[index][name][event.target.name] = event.target.value;
    setBuyerProtecation(data);

  }

  const addPriceRangeBlock = (i, e) => {
    let newfield = {
      range: {
        from: "",
        to: ""
      },
      buy_now: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      },
      auction: {
        buyer: "",
        private_seller: "",
        business_seller: "",
        soletrader_seller: ""
      }
    };

    setBuyerProtecation([...buyerProtecation, newfield]);
  };


  const removePriceRangeBlock = (i, e) => {
    let data = [...buyerProtecation];
    data.splice(i, 1);
    setBuyerProtecation(data);
  };

  return (
    <div class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="#">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Add sub category</li>
              </ul>
              <h2 class="main_title">Add sub category</h2>
            </div>
          </div>
          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="custom_fuild_addM topsmall_top flexcoluman  edit-form">
                <form onSubmit={handleSubmit}>
                  <div class="row">

                    <div class="col-md-6">
                      <div class="form-group">
                        <h4 class="fieldsec_lab">
                          Category
                          <p
                            className="text-danger"
                            style={{ display: "inline-block" }}
                          >
                            *
                          </p>
                        </h4>
                        <div class="">
                          <div class="repwat_one">
                            <select
                              onChange={handleCategoryName}
                              value={categoryId}
                              class="fuilddesh1  "
                            >
                              <option value={""}>{"SELECT CATEGORY"}</option>
                              {allCategory &&
                                allCategory.data &&
                                allCategory.data.map((cat) => {
                                  return (
                                    <option value={cat._id}>
                                      {cat.categoryName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <p
                          className="text-danger"
                          id="category-name"
                          style={{ display: "none" }}
                        >
                          Category Name is required*
                        </p>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <h4 class="fieldsec_lab">
                          Parent category<a href="javascript:void(0)"></a>
                        </h4>
                        <div class="">
                          <div class="repwat_one">
                            <select
                              onChange={handleParentCategory}
                              value={parentId}
                              class="fuilddesh1  "
                            >
                              <option value={""}>
                                {"Select Parent Category"}
                              </option>
                              {
                                (allSubCategory) &&
                                allSubCategory.map((cat) => {
                                  return (
                                    <option value={cat._id}>
                                      {cat.subCategoryName}
                                    </option>
                                  );
                                })

                              }
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <h4 class="fieldsec_lab">
                          Sub category name
                          <p
                            className="text-danger"
                            style={{ display: "inline-block" }}
                          >
                            *
                          </p>
                        </h4>
                        <div class="">
                          <div class="repwat_one">
                            <input
                              onChange={handleSubCategory}
                              type="text"
                              class="fuilddesh1  "
                              placeholder="Sub category name"
                              value={subCategoryName}
                            />
                          </div>
                        </div>
                        <p
                          className="text-danger"
                          id="sub-category-name"
                          style={{ display: "none" }}
                        >
                          Sub category Name is required*
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mt-1">
                        <h4 class="fieldsec_lab">
                          Sub category Image
                        </h4>
                        <div class="">
                          <div class="repwat_one">
                            <input
                              onChange={handleImage}
                              type="file"
                              class="fuilddesh1  "
                              name="img"
                              placeholder="Image"
                            />

                          </div>
                          <p
                            className="text-danger"
                            id="sub-category-image"
                            style={{ display: "none" }}
                          >
                            Category image is required*
                          </p>
                        </div>
                      </div>
                    </div>
                    {
                      !parentId && (
                        <>
                          <div class="col-md-6">
                            <div class="form-check p-4" >
                              <input class="form-check-input " type="checkbox" value="" id="flexCheckChecked" onChange={e => setAllowBuyNow(e.target.checked)} />
                              <label class="form-check-label " for="flexCheckChecked">
                                Allow buy now
                              </label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check p-4" >
                              <input class="form-check-input " type="checkbox" value="" id="allowLiveAuctionChecked" onChange={e => setIsAuctionShow(e.target.checked)} />
                              <label class="form-check-label " for="allowLiveAuctionChecked">
                                Allow Auction Show
                              </label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <h4 className="fieldsec_lab">Buyer protection fee (%)<p className="text-danger" style={{ display: "inline-block" }}>*</p></h4>
                            {buyerProtecation && buyerProtecation.length > 0 && buyerProtecation.map((data, index) => {
                              return (<div key={index}>
                                <div className="row repwat_one">
                                  <div className="col-3">
                                    <p>Price Range</p>
                                  </div>
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="from" type="number" min="0" step="any" placeholder="From" onChange={event => handleFormChange(index, event, 'range')} value={data?.range?.from} />
                                  </div>
                                  <div className="col-3 mb-2">
                                    <input className="fuilddesh" name="to" type="number" min="0" step="any" placeholder="To" onChange={event => handleFormChange(index, event, 'range')} value={data?.range?.to} />
                                  </div>
                                  {index === 0 ?
                                    <div className="col-3 mb-2">
                                      <a className="cursor-pointer" href={void (0)} onClick={event => addPriceRangeBlock(index, event)} >Add more</a>
                                    </div> : <div className="col-3 mb-2">
                                      <a className="cursor-pointer" href={void (0)} onClick={event => removePriceRangeBlock(index, event)} >Remove</a>
                                    </div>}
                                </div>
                                <div className="row repwat_one">
                                  <div className="col-3">
                                    <p>Buy now</p>
                                  </div>
                                  <div className="row col-9 mb-2">
                                    <div className="col-3 mb-2">
                                      <input className="fuilddesh" name="buyer" type="number" min="0" max="100" step="any" placeholder="Buyer" onChange={event => handleFormChange(index, event, 'buy_now')} value={data?.buy_now?.buyer} />
                                    </div>
                                    <div className="col-3 mb-2">
                                      <input className="fuilddesh" name="private_seller" type="number" min="0" max="100" step="any" placeholder="Private seller" onChange={event => handleFormChange(index, event, 'buy_now')} value={data?.buy_now?.private_seller} />
                                    </div>
                                    <div className="col-3 mb-2">
                                      <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business seller" onChange={event => handleFormChange(index, event, 'buy_now')} value={data?.buy_now?.business_seller} />
                                    </div>
                                    <div className="col-3 mb-2">
                                      <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader seller" onChange={event => handleFormChange(index, event, 'buy_now')} value={data?.buy_now?.soletrader_seller} />
                                    </div>
                                  </div>
                                </div>
                                <div className="row repwat_one">
                                  <div className="col-3">
                                    <p>Auction</p>
                                  </div>
                                  <div className="row col-9 mb-2">
                                    <div className="col-3 mb-2">
                                      <input className="fuilddesh" name="buyer" type="number" min="0" max="100" step="any" placeholder="Buyer" onChange={event => handleFormChange(index, event, 'auction')} value={data?.auction?.buyer} />
                                    </div>
                                    <div className="col-3 mb-2">
                                      <input className="fuilddesh" name="private_seller" type="number" min="0" step="any" max="100" placeholder="Private seller" onChange={event => handleFormChange(index, event, 'auction')} value={data?.auction?.private_seller} />
                                    </div>
                                    <div className="col-3 mb-2">
                                      <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business seller" onChange={event => handleFormChange(index, event, 'auction')} value={data?.auction?.business_seller} />
                                    </div>
                                    <div className="col-3 mb-2">
                                      <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader seller" onChange={event => handleFormChange(index, event, 'auction')} value={data?.auction?.soletrader_seller} />
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>);
                            })}
                          </div>
                        </>
                      )
                    }
                    <div class="col-md-6">
                      <div style={{ display: "none" }} class="form-group">
                        <h4 class="fieldsec_lab">
                          Slug
                          <p
                            className="text-danger"
                            style={{ display: "inline-block" }}
                          >
                            *
                          </p>
                        </h4>
                        <div class="">
                          <div class="repwat_one">
                            <input
                              onChange={handleSlug}
                              type="text"
                              class="fuilddesh1 "
                              name=""
                              placeholder="Slug"
                              value={slug}
                            />
                          </div>
                        </div>
                        <p
                          className="text-danger"
                          id="slug"
                          style={{ display: "none" }}
                        >
                          Slug is required*
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bottomsend_button text-center small_margin_top">
                    <button type="submit" className="savesidebutt closefix">
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={handleReset}
                      className="savesidebutt closefix"
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddSubCategory;
