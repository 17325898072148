import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Common = ({ props }) => {
  useEffect(() => {
    if (
      props == "/admin" ||
      props == "/basic-settings" ||
      props == "/email-templates" ||
      props == "/WeeklyDeals" ||
      props == "/CourierServices" ||
      props == "/LiveAuctionDuration"
    ) {
      document.getElementById("collapseDashboard").classList.add("show");
    }
  }, []);

  return (
    <li class="sidebar-dropdown mabot_mainli">
      <a
        href="javascript:void(0)"
        data-toggle="collapse"
        data-target="#collapseDashboard"
      >
        <span>Common</span>
        <i class="fa fa-cog"></i>
      </a>
      <div
        class="sidebar-submenu"
        id="collapseDashboard"
        class="collapse"
        data-parent="#accordion"
      >
        <ul>
          <li>
            <Link to="/admin">Dashboard</Link>
          </li>
          <li>
            <Link to="/basic-settings">Basic Settings</Link>
          </li>
          <li>
            <Link to="/email-templates">Email Templates</Link>
          </li>
          <li>
            <Link to="/balanceActiveNotes">Balance Active Notes</Link>
          </li>
          <li>
            <Link to="/DeactivateReasonList">Account Deactivate Reasons</Link>
          </li>
          <li>
            <Link to="/LiveAuctionDuration">Live Auction Duration</Link>
          </li>
          <li>
            <Link to="/CourierServices">Courier Services</Link>
          </li>
          <li>
            <Link to="/ReportAd">Report Ad</Link>
          </li>
          <li>
            <Link to="/WeeklyDeals">Weekly Deals</Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Common;
