import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import axios from "axios";
import ReactPaginate from "react-paginate";
import AddCourierServices from "../addCourierServices/addCourierServices";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import EditCourierServices from "../editCourierServices/editCourierServices";

const CourierServicesList = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [allRecordsList, setAllRecordsList] = useState();
  const [Id, setId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();

  useEffect(async () => {
    getAllRecordsList();
  }, []);

  const getAllRecordsList = async () => {
    await axios.get(`${PORT}/courierServicesList`).then((response, err) => {
      setAllRecordsList(response);
    });
  };

  useEffect(async () => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit"
    ) {
      getAllRecordsList();
      toast.success("You have successfully edited duration");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
    props.location.MatchValue &&
    props.location.MatchValue == "edit",
  ]);

  useEffect(async () => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "add"
    ) {
      getAllRecordsList();
      toast.success("You have successfully added duration");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
    props.location.MatchValue &&
    props.location.MatchValue == "add",
  ]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const pagesVisited = currentPage * postsPerPage;
  const pageCount =
    Math.ceil(
      (allRecordsList &&
        allRecordsList.data &&
        allRecordsList.data.data &&
        allRecordsList.data.data.length) / postsPerPage
    ) || 1;

  const currentPosts =
    allRecordsList &&
    allRecordsList.data &&
    allRecordsList.data.data &&
    allRecordsList.data.data.slice(pagesVisited, pagesVisited + postsPerPage);

  const paginate = (pagenumber) => {
    setCurrentPage(pagenumber);
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const handlePageChange = ({ selected }) => {
    console.log("selectedpage", selected);
    console.log(pageCount);
    setCurrentPage(selected);
  };

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleAdd = (event) => {
    document.body.classList.add("fixedit_modal");
    setPropsId(undefined);
  };
  const handleEdit = (event, id) => {
    document.body.classList.add("fixedit_modal");
    setPropsId(id);
  };

  const handleModalDelete = (id) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleDeleteYes = () => {
    handleDeleteReason(Id);
    setDeleteModal(false);
  };

  const handleDeleteReason = async (id) => {
    await axios
      .post(`${PORT}/courierServicesDelete`, { params: { id } })
      .then((response, err) => {
        toast.success("You have successfully deleted this duration");
      });
    getAllRecordsList();
  };

  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Courier Services</li>
              </ul>
              <h2 class="main_title">Courier Services</h2>
            </div>
            <div className="addpackk">
              <Link onClick={handleAdd} className="addpkgbtn savesidebutt">
                <i className="fa fa-plus" style={{ padding: "5px" }}></i>Add
                Courier Service
              </Link>
            </div>
          </div>
          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        {/* <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                        <div class="table_cell">Name</div>
                        <div class="table_cell">URL</div>
                        <div class="table_cell">Actions</div>
                      </div>
                      {currentPosts &&
                        currentPosts
                          .filter((val) => {
                            if (searchItem == null) {
                              return val;
                            } else {
                              return false;
                            }
                          })
                          .map((r) => {
                            return (
                              <div class="table_row">
                                {/* <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div> */}
                                <div class="table_cell">{r.name}</div>
                                <div class="table_cell">{r.url}</div>
                                <div class="table_cell">
                                  <span class="table_bbtnn">
                                    <Link
                                      onClick={(e) => handleEdit(e, r._id)}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </Link>
                                  </span>
                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) => handleModalDelete(r._id)}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                    >
                                      <i class="fa fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showingflexx">
          <div class="showingleft">
            Showing {currentPage + 1}-{pageCount} of {pageCount}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal isOpen={deleteModal}>
        <div class="modalChild">
          <div class="modalchildPadding">
            <h6>Do you want to delete this courier service?</h6>
          </div>
          <Button
            onClick={handleDeleteYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>
      {propsid === undefined ? (
        <AddCourierServices />
      ) : (
        <EditCourierServices props={propsid} />
      )}
    </div>
  );
};

export default CourierServicesList;
