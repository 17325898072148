import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const EditCourierServices = ({ props }) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const [name, setName] = useState();
  const [url, setUrl] = useState();

  useEffect(async () => {
    await axios
      .post(`${PORT}/getCourierServicesById`, { params: { id: props } })
      .then((response, err) => {
        setName(response.data.data.name);
        setUrl(response.data.data.url);
      });
  }, [props]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name) {
      document.getElementById("name-text").style.display = "block";
      return;
    }
    if (!url) {
      document.getElementById("url-text").style.display = "block";
      return;
    }
    await axios
      .post(`${PORT}/updateCourierServices`, {
        params: { id: props, name, url },
      })
      .then((response, err) => {
        if (response) {
          handleClose();
          history.push({
            pathname: "/CourierServices",
            MatchValue: "edit",
          });
        }
      });
  };

  const handleReasonText = (event) => {
    document.getElementById("name-text").style.display = "none";
    setName(event.target.value);
  };

  const handleURLText = (event) => {
    document.getElementById("url-text").style.display = "none";
    setUrl(event.target.value);
  };

  const handleClose = (event) => {
    document.body.classList.remove("fixedit_modal");
  };

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn">
          <i class="fa fa-check"></i>
        </a>
        <a onClick={handleClose} class="fixhbtn closefix">
          <i class="fa fa-times"></i>
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Edit Courier Service</h4>
              <div class="Tbaleflex">
                <div class="Table">
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Name</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          value={name}
                          onChange={handleReasonText}
                          type="text"
                          class="fuilddesh"
                          placeholder=""
                        />
                        <p
                          id="name-text"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          Name is required*
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>URL</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          value={url}
                          onChange={handleURLText}
                          type="text"
                          class="fuilddesh"
                          placeholder=""
                        />
                        <p
                          id="url-text"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          URL is required*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">
              SAVE
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditCourierServices;
